<template>
  <Container>
    <Header>
      Orders
      <template #right>
        <BadgeBtn @click="openOrder()">
          + Order
        </BadgeBtn>
      </template>
    </Header>
    <SellingTabe :popOrders="popOrders" class="my-5" />
  </Container>
</template>

<script>
import { computed } from "vue"
import { mapActions } from "vuex"
import icons from "../assets/icons"
import comp from "../components/base"

import SellingTabe from "../components/selling/SellingTable.vue"
import { useStore } from "@/store/store"

export default {
  name: "Orders",
  components: {
    ...comp,
    ...icons,
    SellingTabe,
  },
  setup() {
    return {
      popOrders: computed(() => useStore().getters.popOrders),
    }
  },
  methods: {
    ...mapActions(["openOrder"]),
  },
}
</script>
