<template>
  <Container>
    <Header>
      Containers
      <template #right>
        <BadgeBtn @click="openContainer()">
          + Containers
        </BadgeBtn>
      </template>
    </Header>
    <ContainersTable class="my-5" />
  </Container>
</template>

<script>
import { mapActions } from "vuex"
import comp from "../components/base"
import ContainersTable from "../components/containers/ContainersTable.vue"

export default {
  name: "Containers",
  components: {
    ...comp,
    ContainersTable,
  },
  methods: {
    ...mapActions(["openContainer"]),
  },
}
</script>
